<template>
  <div class="testReport">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="testReport_main">
      <div class="testReport_main_conclusion">
        <div class="testReport_main_conclusion_header">
          <img src="@/assets/exam/report_Tips.png" alt="">
          <span>{{questionBank_info.subjectNodeName}}</span>
        </div>
        <div class="testReport_main_conclusion_view">
          <div class="testReport_main_conclusion_view_total">
            <el-progress type="circle" :percentage="100" :format="format_one"></el-progress>
          </div>
          <div class="testReport_main_conclusion_view_time">
            <span>本次用时</span>
            <span>{{Number(Number(questionBank_info.sumDuration)/60).toFixed(2)}}</span>
            <span>min</span>
          </div>
          <div class="testReport_main_conclusion_view_testNum">
            <p>答题数量：{{questionBank_info.completeNumber}}/{{questionBank_info.countNumber}}</p>
            <p>答对数量：{{questionBank_info.rightNumber}}</p>
            <p>答错数量：{{questionBank_info.errorNumber}}</p>
          </div>
        </div>
      </div>
      <div class="testReport_main_rate">
        <p v-if="this.num == 1">
          <span>{{questionBank_info.totalMaxAccuracy}}%</span>
          <span>全站最高正确率</span>
        </p>
        <p v-else>
          <span>{{questionBank_info.totalMaxScore}}分</span>
          <span>全站最高得分</span>
        </p>
        <p>
          <span>{{questionBank_info.totalAveAccuracy}}%</span>
          <span>全站平均正确率</span>
        </p>
        <p>
          <span>{{questionBank_info.defeatAccuracy}}%</span>
          <span>已击败考生</span>
        </p>
      </div>
      <div class="testReport_main_answerSheet">
        <div class="testReport_main_answerSheet_header">
          <img src="@/assets/exam/report_Tips.png" alt="">
          <span>答题卡</span>
        </div>
        <div class="testReport_main_answerSheet_explain">
          <p>
            <span></span>
            <span>答对</span>
          </p>
          <p>
            <span></span>
            <span>答错</span>
          </p>
          <p>
            <span></span>
            <span>未作答</span>
          </p>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="radioExamList.length>0">
          <div class="testReport_main_answerSheet_item_title">【单项选择题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <!--                        <span  v-for="(item,index) in radioExamList" :key="index" :class="item.isRight==1?'testReport_main_answerSheet_item_right':'testReport_main_answerSheet_item_option'">
                            {{index+1}}
                        </span>  -->
            <span v-for="(item,index) in radioExamList" :key="index" :class="item.className" >
              {{index+1}}
            </span>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="checkboxExamList.length>0">
          <div class="testReport_main_answerSheet_item_title">【多项选择题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <!--                        <span v-for="(item,index) in checkboxExamList" :key="index" :class="item.isRight==1?'testReport_main_answerSheet_item_right':'testReport_main_answerSheet_item_option'">-->
            <!--                            {{index+1}}-->
            <!--                        </span>-->
            <span v-for="(item,index) in checkboxExamList" :key="index" :class="item.className" >
              {{index+1}}
            </span>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="estimateList.length>0">
          <div class="testReport_main_answerSheet_item_title">【判断题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <!--                        <span  v-for="(item,index) in estimateList" :key="index" :class="item.isRight==1?'testReport_main_answerSheet_item_right':'testReport_main_answerSheet_item_option'">
                            {{index+1}}
                        </span>-->
            <span v-for="(item,index) in estimateList" :key="index" :class="item.className" >
              {{index+1}}
            </span>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="budingxiang.length>0">
          <div class="testReport_main_answerSheet_item_title">【不定项选择题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in budingxiang" :key="index" style="display: flex;">
              <!-- {{index+1}} -->
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>    
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="jisuanfenxi.length>0">
          <div class="testReport_main_answerSheet_item_title">【计算分析题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in jisuanfenxi" :key="index"  style="display: flex;">
             
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="synthesisList.length>0">
          <div class="testReport_main_answerSheet_item_title">【综合题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in synthesisList" :key="index" style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="shortAnswerList.length>0">
          <div class="testReport_main_answerSheet_item_title">【简答题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in shortAnswerList" :key="index" style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="jisuanhuida.length>0">
          <div class="testReport_main_answerSheet_item_title">【计算回答题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in jisuanhuida" :key="index" style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="anlifenxi.length>0">
          <div class="testReport_main_answerSheet_item_title">【案例分析题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in anlifenxi" :key="index"  style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="jisuan.length>0">
          <div class="testReport_main_answerSheet_item_title">【计算题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in jisuan" :key="index" style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)">
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>
        </div>
        <div class="testReport_main_answerSheet_item" v-if="zonghefenxi.length>0">
          <div class="testReport_main_answerSheet_item_title">【综合分析题】</div>
          <div class="testReport_main_answerSheet_item_box">
            <div v-for="(item,index) in zonghefenxi" :key="index" style="display: flex;">
              <span v-for="(items,indexs) in item.subjectList" :class="returnClass(items)" >
                {{(index+1)+'.'+(indexs+1)}}
              </span>
            </div>
          </div>

        </div>
      </div>
      <div class="testReport_main_objective">
        <div class="testReport_main_objective_header">客观答题情况</div>
        <div class="testReport_main_objective_view">
          <div>
            <p>答对题数</p>
            <p>
              <span>{{questionBank_info.rightNumber}}</span>
              <span>题</span>
            </p>
          </div>
          <div>
            <p>答错题数</p>
            <p>
              <span>{{questionBank_info.errorNumber}}</span>
              <span>题</span>
            </p>
          </div>
        </div>
        <div class="testReport_main_objective_btn">
          <el-button type="primary" @click="again()">再次挑战</el-button>
        </div>
      </div>
      <div class="testReport_main_echarts">
        <div class="testReport_main_echarts_title">成绩曲线</div>
        <div class="" style="width:100%;display:flex;justify-content: center;">
          <div class="testReport_main_echarts_view" id="echarts" style="width: 600px;height: 300px;">
          </div>
        </div>
        <div class="testReport_main_echarts_btn">
          <el-button type="primary" @click="analysis(2)">全部解析</el-button>
          <el-button type="primary" @click="analysis(1)">错题解析</el-button>
          <el-button type="primary" @click="again()" v-if="tabIndex==1 || tabIndex==2">再次练习</el-button>
          <el-button type="primary" @click="goHome()">返回首页</el-button>
        </div>
      </div>
    </div>
    <t_footer></t_footer>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { assignment_exercise, analysis_list, analysis_new, subject_analysisList, assignment_paper ,analysisList_new} from "@/api/exam";
export default {
  name: 'testReport',
  components: {
    t_header,
    t_footer,
  },
  data() {
    return {
      examObj: {},
      nodeType: 0,
      questionBank_info: {},
      analysisObj: {},
      radioExamList: [],// 单选题集合
      checkboxExamList: [],// 多选题集合
      estimateList: [],// 判断体集合
      budingxiang: [],//不定向选择题
      jisuanfenxi: [],//计算分析题
      synthesisList: [],// 综合体集合
      shortAnswerList: [],// 简答题集合
      jisuanhuida: [],// 计算回答
      anlifenxi: [],//案例分析
      jisuan: [],//计算
      zonghefenxi: [],//综合分析
      nodeId: '',
      testPaperId: '',
      isTrue: 2,
      twoIindex: 0,
      tabIndex: 0,
      num: 0,
      isUserInfo: false,
      echartsHangList: [],
      echartsShuList: [],
      examInfo: {}
    }
  },
  mounted() {
    var that = this;
    var pageQuery = that.$route.query;
    var testReportInfo = localStorage.getItem("testReport")
    if (testReportInfo) {
      testReportInfo = JSON.parse(testReportInfo)


      that.examInfo = testReportInfo.examInfo
      that.num = testReportInfo.num
      that.twoIindex = testReportInfo.twoIindex

      that.tabIndex = testReportInfo.tabIndex
      that.firstId = testReportInfo.firstId
      that.secondId = testReportInfo.secondId
    }
    // if(this.$route.query.value==true){
    //     this.isTrue=2
    // }else{
    //     this.isTrue=1
    // }
    if (this.num == 1) {
      this.nodeId = pageQuery.nodeId
      this.nodeType = pageQuery.nodeType,
      this.assignment_exercise();
      this.analysis_new();
    } else {
      // this.nodeType=2
      this.testPaperId = pageQuery.testPaperId
      // this.isTrue=num
      this.assignment_paper();
      //this.subject_analysisList()
      this.subject_analysisList_new();
    }
  },
  methods: {
    returnClass(item){
      if (item.isRight == 1) {
        return 'testReport_main_answerSheet_item_right';
        //错误
      } else if (item.isRight == 2) {
        return  'testReport_main_answerSheet_item_option';
        //未作答
      } else {
        return 'testReport_main_answerSheet_item_unanswered';
      }

    },
    again() {
      var twoIindex = ''
      if (this.tabIndex == '6' || this.tabIndex == '8') {
        twoIindex = this.tabTwoIindex
      } else {
        twoIindex = ''
      }
     
      var examTextInfo = localStorage.getItem("tiku_examText");
      if (examTextInfo) {
        examTextInfo = JSON.parse(examTextInfo);
        examTextInfo.value = 2;
        localStorage.setItem('tiku_examText',JSON.stringify(examTextInfo));
      }
      if (this.tabIndex == 1 || this.tabIndex == 2) {
       
        this.$router.push({
          path: '/tikuDetail',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId
          }
        })

      } else if (this.tabIndex == 3 || this.tabIndex == 4 || this.tabIndex == 5 ) {
        this.$router.push({
          path: '/tikuDetail',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            // value: 2,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId,
            testPaperId:this.testPaperId,
          }
        })
      }else if(this.tabIndex == 9){
        this.$router.push({
          path: '/mnjktiku',
          query: {
            // chapterListItem: JSON.stringify(this.examInfo),
            // num:num,
            tabIndex: this.tabIndex,
            // value: 2,
            twoIindex: twoIindex,
            firstId: this.firstId,
            secondId: this.secondId,
            testPaperId:this.testPaperId,
            type:this.secondId
          }
        })

      }

    },
    // echarts
    drawLine() {
      var that = this
      var myChart = that.$echarts.init(document.getElementById('echarts'))
      // 基于准备好的dom，初始化echarts实例
      // 绘制图表
      var option = {

        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: that.echartsHangList,
            minInterval: 1,
            splitNumber: 5
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: '100'
          }
        ],
        series: [

          {
            name: '',
            type: 'line',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            symbol: 'circle',
            symbolSize: 5,
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: '#0770FF'
            },
            // areaStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         {
            //             offset: 0,
            //             color: 'rgba(213,72,120,0.8)'
            //         },
            //         {
            //             offset: 1,
            //             color: 'rgba(213,72,120,0.3)'
            //         }
            //     ])
            // },
            data: that.echartsShuList
          }
        ]
      };
      myChart.setOption(option);
    },
    // 父传子
    getTodos(e) {
      this.isUserInfo = e
    },
    // 去解析
    analysis(num) {
      this.$router.push({
        path: '/tikuAnalysis',
        query: {
          tabIndex: this.tabIndex,
          twoIindex: this.twoIindex,
          isError: num,
          num: this.num,
          nodeType: this.nodeType,
          nodeId: this.nodeId,
          testPaperId: this.testPaperId
        }
      })
    },
    goHome() {
      this.$router.push({
          path: '/tiku_examcenter',
          query: {
           
            firstId: this.firstId,
            secondId: this.secondId
          }
        })
    },
    // 章节和专项练习
    assignment_exercise() {
      var obj = {
        nodeId: this.nodeId,
        nodeType: this.nodeType,
        terrace:'2'
      }
      assignment_exercise(obj).then(res => {
        if (res.data.code == 0) {
          this.questionBank_info = res.data.data
          var arr = []
          var arr2 = []
          for (var i in this.questionBank_info.scoreList) {
            arr.push(this.questionBank_info.scoreList[i].accuracy)
            arr2.push(Number(i) + 1)
          }
          this.echartsHangList = arr2
          this.echartsShuList = arr
          this.$nextTick(() => {
            // this.init();
            this.drawLine()
          });
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 章节练习的解析 -- new
    analysis_new() {
      this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'});
      var that=this;
      var obj = {
        nodeId: this.nodeId,
        nodeType: this.nodeType,
        type: this.isTrue,
      }
      analysis_new(obj).then(res => {
        if (res.data.code == 0) {
          this.analysisObj = res.data.data
          for (var i in res.data.data) {
            //正确
            if (res.data.data[i].isRight == 1) {
              res.data.data[i].className = 'testReport_main_answerSheet_item_right';
              //错误
            } else if (res.data.data[i].isRight == 2) {
              res.data.data[i].className = 'testReport_main_answerSheet_item_option';
              //未作答
            } else {
              res.data.data[i].className = 'testReport_main_answerSheet_item_unanswered';
            }
            if (res.data.data[i].classify == "1") {
              this.radioExamList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "2") {
              this.checkboxExamList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "3") {
              this.estimateList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "4") {
              this.budingxiang.push(res.data.data[i])
            } else if (res.data.data[i].classify == "5") {
              this.jisuanfenxi.push(res.data.data[i])
            } else if (res.data.data[i].classify == "6") {
              this.synthesisList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "7") {
              this.shortAnswerList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "8") {
              this.jisuanhuida.push(res.data.data[i])
            } else if (res.data.data[i].classify == "9") {
              this.anlifenxi.push(res.data.data[i])
            } else if (res.data.data[i].classify == "10") {
              this.jisuan.push(res.data.data[i])
            } else if (res.data.data[i].classify == "11") {
              this.zonghefenxi.push(res.data.data[i])
            }
          }
          that.loading.close()

        } else {
          that.loading.close()
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-交卷or答题报告
    assignment_paper() {
      var obj = {
        testPaperId: this.testPaperId,
        terrace:'2'
      }
      assignment_paper(obj).then(res => {
        if (res.data.code == 0) {
          this.questionBank_info = res.data.data;
          var arr = []
          var arr2 = []
          for (var i in this.questionBank_info.scoreList) {
            arr.push(this.questionBank_info.scoreList[i].score)
            arr2.push(Number(i) + 1)
          }
          this.echartsHangList = arr2
          this.echartsShuList = arr
          this.$nextTick(() => {
            // this.init();
            this.drawLine()
          });
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-题解析列表 - new
    subject_analysisList_new() {
      this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'});
      var that=this;
      var obj = {
        testPaperId: this.testPaperId,
        type: this.isTrue,
      }
      analysisList_new(obj).then(res => {
        if (res.data.code == 0) {
          this.analysisObj = res.data.data
          for (var i in res.data.data) {
            //正确
            if (res.data.data[i].isRight == 1) {
              res.data.data[i].className = 'testReport_main_answerSheet_item_right';
              //错误
            } else if (res.data.data[i].isRight == 2) {
              res.data.data[i].className = 'testReport_main_answerSheet_item_option';
              //未作答
            } else {
              res.data.data[i].className = 'testReport_main_answerSheet_item_unanswered';
            }
            if (res.data.data[i].classify == "1") {
              this.radioExamList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "2") {
              this.checkboxExamList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "3") {
              this.estimateList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "4") {
              this.budingxiang.push(res.data.data[i])
            } else if (res.data.data[i].classify == "5") {
              this.jisuanfenxi.push(res.data.data[i])
            } else if (res.data.data[i].classify == "6") {
              this.synthesisList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "7") {
              this.shortAnswerList.push(res.data.data[i])
            } else if (res.data.data[i].classify == "8") {
              this.jisuanhuida.push(res.data.data[i])
            } else if (res.data.data[i].classify == "9") {
              this.anlifenxi.push(res.data.data[i])
            } else if (res.data.data[i].classify == "10") {
              this.jisuan.push(res.data.data[i])
            } else if (res.data.data[i].classify == "11") {
              this.zonghefenxi.push(res.data.data[i])
            }
          }
          that.loading.close()

        } else {
          that.loading.close()
          this.$message.error(res.data.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 历年/模拟/考前试卷-题解析列表
    // 进度条的文字内容显示
    format_one() {
      if(this.num == 1){
        return `${this.questionBank_info.accuracy}` + '%';
      }else{
        return `${this.questionBank_info.score}` + '分';
      }
      
    },
  }
}
</script>

<style>
</style>